import { handleUrlByGet, decryptHandleTestNew } from './handleUrl.js';

export async function ajaxGet (url, params) {
    return new Promise((resolve, reject) => {
        const handelUrl = handleUrlByGet(params, url);
        const xhr = new XMLHttpRequest();
        xhr.open('GET', handelUrl, true);
        xhr.send();
        xhr.onreadystatechange = () => {
            if (xhr.readyState === 4 && xhr.status === 200) {
                const text = decryptHandleTestNew({ data: JSON.parse(xhr.response) });
                resolve(text.data.data);
            }
        };
        xhr.onerror = () => {
            reject('请求异常');
        }
    });
}

export function ajaxJsonp(url, params) {
  return new Promise((resolve, reject) => {
    // 创建一个唯一的回调函数名
    const callbackName = `jsonpCallback${Math.random().toString(36).substr(2, 9)}`;
    
    // 构建请求 URL
    params = params || {};
    params.callback = callbackName;
    const queryString = new URLSearchParams(params).toString();
    const fullUrl = `${url.indexOf('?') !== -1 ? url+'&'+queryString : url+'?'+queryString}`;
    const handelUrl = handleUrlByGet(params, fullUrl);

    // 创建 <script> 标签并设置 src 属性
    const script = document.createElement('script');
    script.src = handelUrl;

    // 定义全局回调函数
    window[callbackName] = (data) => {
      resolve(data);
      document.head.removeChild(script);
      delete window[callbackName];
    };

    // 监听脚本加载错误
    script.onerror = () => {
      reject(new Error('JSONP request failed'));
      document.head.removeChild(script);
    };

    // 将 <script> 标签添加到文档中
    document.head.appendChild(script);
  });
};
