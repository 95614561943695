import NodeRSA from 'node-rsa';
import { Buffer } from 'buffer';
import { publicEncrypt, constants } from 'crypto';
import CryptoJS from 'crypto-js';

const whichEnv = window.location.hostname === 'download.jiaoyihang.com' ? 'production' : 'development';

const publicKeys = {
    development: '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCcPo18xxmIxz7qml/K7d5PYUxH3454W1V7/j/PeK48yO9r8U560sIwdD1YW0vkEjL7IDURWKZOMw1ab1lhBiA02WbvfDiM8tJmH7EDP0EZPK+AiUwFrLtt/zsuYb6FHaqXdGPeOxem0SzJ6/pX/w8kAV7QoPUFlwsuXfk5BBDZcwIDAQAB-----END PUBLIC KEY-----',
    pre: '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCdxVfnp1p5X/LIjSN3aWhCW9yRDGnc99ZwHk7muqnHwDenUYdzYHfAJ3oeKXg8AZyR1vhMrUJgUiWPYky+VzmWP+Pn1cNZzJ5hVV/bOH+2l/Uf8FCkmKyC56Toi0p6rYhbTy+0pXxm0G1ld0+kH8WhgXq2iXB9FBEFKxmFNhvfJwIDAQAB-----END PUBLIC KEY-----',
    production: '-----BEGIN PUBLIC KEY-----MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCdxVfnp1p5X/LIjSN3aWhCW9yRDGnc99ZwHk7muqnHwDenUYdzYHfAJ3oeKXg8AZyR1vhMrUJgUiWPYky+VzmWP+Pn1cNZzJ5hVV/bOH+2l/Uf8FCkmKyC56Toi0p6rYhbTy+0pXxm0G1ld0+kH8WhgXq2iXB9FBEFKxmFNhvfJwIDAQAB-----END PUBLIC KEY-----'
};

const httpDomains = {
    development: 'https://exchange-api.jyh.aml52.com',
    pre: 'https://exchange-api-pre.jyh.aml52.com',
    production: 'https://exchange-api-pro.jyh.aml52.com'
};

const urlDomain = httpDomains[whichEnv];
const publicKeyWithTitle = publicKeys[whichEnv];
const b64map = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
const b64pad = '=';

const handleUrlFn = {
    hex2b64: (d) => {
        let b;
        let e;
        let a = '';
        for (b = 0; b + 3 <= d.length; b += 3) {
            e = parseInt(d.substring(b, b + 3), 16);
            a += b64map.charAt(e >> 6) + b64map.charAt(e & 63);
        }
        if (b + 1 == d.length) {
            e = parseInt(d.substring(b, b + 1), 16);
            a += b64map.charAt(e << 2);
        } else {
            if (b + 2 == d.length) {
                e = parseInt(d.substring(b, b + 2), 16);
                a += b64map.charAt(e >> 2) + b64map.charAt((e & 3) << 4);
            }
        }
        while ((a.length & 3) > 0) {
            a += b64pad;
        }

        return a;
    },

    toSign: (obj) => {
        let signKey = '';
        if (whichEnv == 'development') {
            signKey = 'e162cf56-1d06-4e49-ab11-384053c03f3a';
        } else if (whichEnv == 'pre' || whichEnv == 'production') {
            signKey = 'Q74LVaOA7mZP8aqh6htjWUJlp0uGFPj1';
        }
        // 按照升序排序obj属性
        const sortObj = obj;
        sortObj.timestamp = String(Date.parse(new Date()) / 1000);
        const newkey = Object.keys(sortObj).sort();
        const newObj = {};
        newkey.forEach((item, i) => {
            newObj[newkey[i]] = sortObj[newkey[i]];
        });
        const keyArr = new Array();
        for (const y in newObj) {
            let x = newObj[y];
            if (typeof x == 'object') {
                x = JSON.stringify(x);
            }
            keyArr.push(y + '=' + x);
        }
        const signStr = keyArr.join('&');
        const signHash = CryptoJS.HmacSHA1(signStr, signKey);
        const signHashStr = CryptoJS.enc.Hex.stringify(signHash);
        newObj.sign = signHashStr;

        return newObj;
    },

    // 解密 NodeRSA
    decryptHandle: (text) => {
        const public_key = new NodeRSA(publicKeyWithTitle);
        const decryptPublicHandle = public_key.decryptPublic(text, 'utf-8');
        const content = decryptPublicHandle ? JSON.parse(decryptPublicHandle) : decryptPublicHandle;

        return content;
    },

    // 加密 crypto
    encryptHandle: (Args, flag) => {
        const data = flag ? Args : JSON.stringify(Args);
        const buffer = Buffer.from(data);
        const blocks = Math.ceil(buffer.length / 117);
        const cipherBuffer = Buffer.alloc(blocks * 128);
        for (let i = 0; i < blocks; i++) {
            const chunk = buffer.slice(i * 117, (i + 1) * 117);
            let chunkEncrypt = publicEncrypt({
                key: publicKeyWithTitle,
                padding: constants.RSA_PKCS1_PADDING
            }, chunk);
            while (chunkEncrypt.length < 128) {
                chunkEncrypt = publicEncrypt({
                    key: publicKeyWithTitle,
                    padding: constants.RSA_PKCS1_PADDING
                }, chunk);
            }
            chunkEncrypt.copy(cipherBuffer, i * 128);
        }
        const encryptData = handleUrlFn.hex2b64(cipherBuffer.toString('hex'));

        return {
            'rsa_params': flag ? encryptData : encodeURIComponent(encryptData)
        };
    }
};

export function handleUrlByGet (params, requestUrl) {
    requestUrl = `${urlDomain}/${requestUrl}`;
    let args = '';
    let Args = params || {};
    let urlStr = requestUrl;
    for (const key in Args) {
        if (Args[key] == undefined) {
            Args[key] = '';
        }
    }
    // 增加sign验签参数
    Args = handleUrlFn.encryptHandle(handleUrlFn.toSign(Args, requestUrl));
    const arr = new Array();
    for (const k in Args) {
        const v = Args[k];
        arr.push(k + '=' + v);
    }
    args = arr.join('&');
    urlStr += (urlStr.indexOf('?') == -1 ? '?' : '&') + args;
    const signMsg = {};
    signMsg['增加sign验签参数后加密对象'] = Args;
    signMsg['请求接口加密后地址'] = urlStr;

    return urlStr;
}

export function handleUrlByPost (params, consoleLog) {
    let Args = params;
    Args = handleUrlFn.encryptHandle(JSON.stringify(params), true);
    const signMsg = {};
    signMsg['增加sign验签参数后加密对象'] = Args;
    consoleLog.setLogMsg('4-加密后信息', signMsg);

    return JSON.stringify(Args);
}

export function decryptHandleTest (text) {
    const textParse = JSON.parse(text);
    textParse.data = handleUrlFn.decryptHandle(textParse.data);

    return JSON.stringify(textParse);
}

export function decryptHandleTestNew (res) {
    const textParse = res;
    textParse.data.data = handleUrlFn.decryptHandle(textParse.data.data);

    return textParse;
}

export function escapeHTML (a) {
    a = '' + a;

    return a.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;').replace(/'/g, '&apos;');
}
