<template>
  <div id="app" :style="bodyWidthVars">
    <el-scrollbar>
      <div class="content-wrapper">
        <!-- 主内容 -->
        <transition name="fade-transform" mode="out-in">
          <router-view />
        </transition>
      </div>
    </el-scrollbar>
  </div>
</template>

<script>
import Vue from 'vue';
import { ajaxGet } from '@/utils/ajax.js';
export default {
  name: 'App',
  data() {
    return {
      bodyWidth: '1200px',
    };
  },
  computed: {
    bodyWidthVars() {
      return {
        '--bodyWidth': this.bodyWidth,
      };
    },
  },
  created() {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|MicroMessenger|QQ/i.test(
      navigator.userAgent
    );
    this.bodyWidth = isMobile ? '100%' : '1200px';
    // 获取Android下载链接
    ajaxGet('user/pb/api/app/system/v1/checkVersion').then(r => {
      Vue.prototype.downloadUrl = r.apkUrl;
    });
  },
};
</script>

<style lang="scss">
.content-wrapper {
  position: relative;
}
#app {
  min-width: var(--bodyWidth);
}
#app .el-scrollbar__view {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  .content-wrapper {
    flex: 1;
  }
}
/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all 0.3s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>
